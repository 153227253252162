import React, { Fragment, FunctionComponent, useEffect, useState, useRef, ChangeEvent } from 'react';
import { ApplicationState } from '../../redux/States';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { downloadDataAsExcel, SheetHeaderDisplay} from '../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Form, InputGroup, FormControl, Col, Row as BSRow, Table, FormCheck, Modal, Container, } from 'react-bootstrap';
import { AuthedLayout, EditableTableDiv, HeaderDisplayKeysWithType, InputDropdownGroup } from '../../components';
import { StudentGradeNoSeat, BodyMindN } from '../../model';
import apis from '../../apis';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { DateTime } from 'luxon';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const bodyMindBookN: FunctionComponent<Props> = ({ 
  user,
  loading,
  catchErrorForModal 
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  type MetricPageData = StudentGradeNoSeat & BodyMindN;
  const degreeObject = {
    1: '輕度',
    2: '中度',
    3: '極重度',
  };
  const { currentSemester = '', semesters = {} } = user || {};
  const [currentSem, setCurrentSem] = useState(currentSemester);
  const { year = -1, sem = -1 } = semesters[currentSem];
  const degreeInputRef = useRef<HTMLInputElement>(null);
  const studentInputRef = useRef<HTMLInputElement>(null);
  const showInStudentsData = useRef<HTMLInputElement>(null);
  const [insertBtn, setInsertBtn] = useState<boolean>(true);
  const [sid, setSid] = useState<string>('');
  const [pid, setPid] = useState<string>('');
  const [grade, setGrade] = useState<number>();
  const [no, setNo] = useState<number>();
  const [seat, setSeat] = useState<number>();
  const [checkField, setCheckField] = useState<number>(0);
  const [bodyMindKindItems, setBodyMindKindItems] = useState<object>();
  const [bodyMindKindDropDownItems, setBodyMindKindDropDownItems] = useState<Record<number, string>>();
  const [BodyMindKindDropDown, setBodyMindKindDropDown] = useState<number>();
  const [degreeDropDown, setdegreeDropDown] = useState<number>();
  const [isDiseaseValid, setIsDiseaseValid] = useState<boolean>(false);
  const [treatment, setTreatment] = useState<string>('');
  
  const [students, setStudent] = useState([] as MetricPageData[]);  
  const [deleting, setDeleting] = useState(false);
  const [deletingStudent, setDeletingStudent] = useState<MetricPageData>();
  const displayHeader: (SheetHeaderDisplay<MetricPageData> & 
    HeaderDisplayKeysWithType<MetricPageData>)[] = [
    { property: 'studentId', display: '統編' },
    { property: 'bodyMindId', display: '診斷代號' },
    { property: 'degree', display: '等級'},
    { 
      property: 'createDate', 
      display: '輸入日期', 
      onRender: (v) => {
        return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
      },
      onSheetRender: (v) => {
        if(v) {
          return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
        }
        return '';
      },
    },
    { property: 'bodyMindName', display: '疾病名稱' },
    { property: 'grade', display: '年級' },
    { property: 'no', display: '班級' },
    { property: 'seat', display: '座號' },
    {
      property: 'sex', 
      display: '性別',
      onRender: (v) => (v === '1' ? '男' : '女'),
      onSheetRender: (v) => (v === '1' ? '男' : '女'), 
    },
    { property: 'name', display: '學生' },
  ];
 
  useEffect(() => {
    apis.getBodyMindKindsDropDown()
    .then((s) => {
      if(s.length) {
        setBodyMindKindItems(s);
        const results = s.reduce((obj, item) => {
          obj[item.id] = item.disease;
          return obj;
        }, {} as Record<number, string>);
        setBodyMindKindDropDownItems(results);
        getStudentAll();
      }
    })
    .catch(catchErrorForModal);
  }, [user, year, sem]);

  useEffect(() => { 
    // 檢查疾病代號
    setIsDiseaseValid(false);
    if (BodyMindKindDropDown) {
      if(bodyMindKindItems) {
        Object.values(bodyMindKindItems).forEach((item, index) => {
          if(item.id == BodyMindKindDropDown) {
            setIsDiseaseValid(true);
            setTreatment(item.treatment);
          }
        });
      }
      
      // 檢查是否可以點選新增按鈕
      if(studentInputRef.current?.value) {
        setInsertBtn(false); 
        CheckInput();
      }
  }
  }, [BodyMindKindDropDown]);

  // 檢查是否可以點選新增按鈕
  function CheckInsertBtn() {
    setInsertBtn(true);
    if (BodyMindKindDropDown) {
        if(studentInputRef.current?.value) {
          if(degreeDropDown) {
            setInsertBtn(false); 
            CheckInput();
          }
        }
    }
  }

  function CheckInput() {
    const inputStudent = studentInputRef.current?.value;
    
    // 檢查輸入身分證、學號或班級座號
    if(inputStudent) {
      if(inputStudent.substring(0, 1) == '=') {
        setSid(inputStudent.substring(1));
        setCheckField(2);
      } else {
        switch(inputStudent.length) {
          case 5:
            setGrade(parseInt(inputStudent.substring(0, 1)));
            setNo(parseInt(inputStudent.substring(1, 3)));
            setSeat(parseInt(inputStudent.substring(3, 5)));
            setCheckField(3);
            break;
          case 6:
            // 代入年級、班級、座號
            setGrade(parseInt(inputStudent.substring(0, 2)));
            setNo(parseInt(inputStudent.substring(2, 4)));
            setSeat(parseInt(inputStudent.substring(2, 4)));
            setCheckField(3);
            break;
          case 10:
          case 11:
          case 12:
            setPid(inputStudent);
            setCheckField(1);
            break;
        }
      }
    }
  }

  function insertDiseaseCheck() {
    // 檢查診斷代號
    if(isDiseaseValid) {
      // 檢查有無年級班級座號
      if(checkField == 3) {
        if(!grade || !no || !seat) {
          toast.error(`找不到該名學生!`);
          return
        }
      }
    } else {
      toast.error("診斷代號錯誤!", {});
      return
    }
    insertDisease();
  }

  function insertDisease() {
    if(BodyMindKindDropDown && degreeDropDown) {
      // 送出api
      toast
      .promise(
        apis.insertBodyMinds(
          year, 
          sem,
          sid,
          seat || 0,
          no || 0,
          grade || 0,
          pid,
          BodyMindKindDropDown,
          degreeDropDown,
          checkField
        ),
        {
          pending: '資料新增中......',
          success: '新增成功！',
        }
      )
      .then((r) => {
        // 清空學號、診斷代號、等級
        if(studentInputRef.current) {
          studentInputRef.current.value = '';
        }
        setBodyMindKindDropDown(undefined);
        setdegreeDropDown(1);
        CheckInsertBtn(); // 按鈕diabled

        getStudentAll();
      })
      .catch(catchErrorForModal);
    }
  }

  function getStudentAll() {
    apis.getBodyMindBookNAll(year, sem)
    .then((s) => {
      setStudent(s.map(({ bodyMind, ...m }) => ({
        ...bodyMind,
        ...m
      })));
    })
    .catch(catchErrorForModal);
  }

  function getStudentInSchool() {
    if(showInStudentsData.current?.checked) {
      apis.getBodyMindInSchoolList(year, sem)
      .then((s) => {
        setStudent(s.map(({ bodyMind, ...m }) => ({
          ...bodyMind,
          ...m
        })));
      })
      .catch(catchErrorForModal);
    } else {
      getStudentAll();
    }
  }

  function onDeleteMetric(student: MetricPageData) {
    setDeleting(true);
    setDeletingStudent(student);
  }

  function onHideDeleting() {
    setDeleting(false);
    setDeletingStudent(undefined);
  }

  function deleteBodyMinds() {
    if(deletingStudent?.id) {   
      toast
      .promise(
        apis.deleteStudentDeleteBodyMinds(
          deletingStudent?.id,
          deletingStudent?.studentId,
          deletingStudent?.bodyMindId,
          deletingStudent?.bodyMindName,
          deletingStudent?.degree,
        ),
        {
          pending: '資料刪除中......',
          success: '刪除成功！',
          error: '刪除失敗！請查看錯誤資訊。',
        }
      )
      .then((s) => {
        // 關閉刪除彈窗 
        setDeleting(false);
        setDeletingStudent(undefined);
        // 重新載入領有身心障礙手冊清單
        getStudentAll();
      })
      .catch(catchErrorForModal);
    }
  }

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        診斷代號：
        <Col sm={2}>
          <InputDropdownGroup
            className={'p-1 text-center'}
            name="diseaseId"
            type='number'
            options={bodyMindKindDropDownItems || {}}
            defaultShow="無異常"
            value={BodyMindKindDropDown || ''}
            onChange={(
              e: ChangeEvent<
                HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
              >
            ) => {
              setBodyMindKindDropDown(parseInt(e.target.value));
            }}
            onBlur={() => {}}
            onFocus={() => {}}
          />
        </Col>
        等級：
        <Col xs={1} className="mr-2">
          <InputDropdownGroup
            className={'p-1 text-center'}
            name="degree"
            type='number'
            options={{
              1: '輕度',
              2: '中度',
              3: '極重度',
            }}
            defaultShow="無異常"
            value={degreeDropDown}
            onChange={(
              e: ChangeEvent<
                HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
              >
            ) => {
              setdegreeDropDown(parseInt(e.target.value));
            }}
            onBlur={() => {}}
            onFocus={() => {}}
          />
        </Col>
        請輸入年級班級座號或身分證或學號(學號前請加=)：
        <Col xs={2} className="mr-2">
            <FormControl 
              type="string"
              aria-label="studentInput"
              aria-describedby="basic-addon1"
              ref={studentInputRef}
              onChange={CheckInsertBtn}
            />
        </Col>
        <Button
          disabled={insertBtn}
          onClick={insertDiseaseCheck}
        >
          新增
        </Button>
      </BSRow>
      <BSRow className='mt-4 mb-2'>
        <Col sm="8">
          <div className='d-flex'>
            <FormCheck
              className='ml-3'
              ref={showInStudentsData}
              label="只顯示在學學生"
               onChange={(e) => getStudentInSchool()}
            />
          </div>
        </Col>
        <Col sm="4" className='text-right'>
          <Button
            disabled={!students.length}
            variant="success"
            className="text-dark"
            onClick={() => {
              downloadDataAsExcel({
                title: `${year}學年第${sem}學期領有身心障礙手冊清單`,
                values: students,
                headers: displayHeader,
                footer:
                  '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
              });
            }}
          >
            Excel下載
          </Button>
        </Col>
      </BSRow>
      <BSRow className='mb-2'>
        <Col className="text-center">
          {year}學年第{sem}學期領有身心障礙手冊清單
        </Col>
      </BSRow>
      <BSRow>
        <EditableTableDiv
          deleteable
          values={students}
          headers={displayHeader}
          onDelete={onDeleteMetric}
        />
      </BSRow>
      <React.Fragment>
        <Modal
          show={deleting}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton onHide={onHideDeleting}>
            <Modal.Title id="contained-modal-title-vcenter">
              領有身心障礙手冊 - {deletingStudent?.grade}年{deletingStudent?.no}班{deletingStudent?.seat}號 {deletingStudent?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <BSRow className='mb-3'>
                <Col sm={6}> 統編：</Col>
                <Col sm={6}>{deletingStudent?.pid}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}>診斷代號：</Col>
                <Col sm={6}>{deletingStudent?.bodyMindId}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}>等級：</Col>
                <Col sm={6}>{deletingStudent?.degree}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}> 學生：</Col>
                <Col sm={6}>{deletingStudent?.name}</Col>
              </BSRow>
              <BSRow className='mb-3'>
                <Col sm={6}> 疾病名稱：</Col>
                <Col sm={6}>{deletingStudent?.bodyMindName}</Col>
              </BSRow>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" type="submit" onClick={deleteBodyMinds}>
              刪除
            </Button>
            <Button type="reset" variant="secondary" onClick={onHideDeleting}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </AuthedLayout>
  );
};

export const BodyMindBookN = connector(bodyMindBookN);
