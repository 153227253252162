import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import { z } from 'zod';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTable,
  HeaderDisplayKeysWithType,
} from '../components';
import Apis from '../apis';
import { CheckField, StudentFile, StudentFileZod } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { checkOkToUpload, takeClassStudent } from '../utils';
import { ClassSem } from '../layouts';
import { useActiveSems } from '../hook';

const inputFileParser = z.array(StudentFileZod);
const headerDisplayKeys: HeaderDisplayKeysWithType<StudentFile>[] = [
  { display: '備註', property: 'remark' },
  { display: '身份證字號', property: 'pid', style: { width: '7%' } },
  { display: '姓名', property: 'name', style: { width: '5%' } },
  { display: '學號', property: 'sid', style: { width: '6%' } },
  { display: '入學年', property: 'year', style: { width: '5%' } },
  // {
  //   display: '年級',
  //   property: 'grade',
  //   onRender: (v) => I18N.Grades[v] || 'Unknown',
  // },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '性別', property: 'sex' },
  {
    display: '生日',
    property: 'birth',
    style: { width: '7%' },
    onRender: (v) => (v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null),
  },
  { display: '血型', property: 'blood' },
  { display: '身分', property: 'aborigine' },
  { display: '父親', property: 'dad' },
  { display: '母親', property: 'mom' },
  { display: '監護人', property: 'guardian', style: { width: '5%' } },
  { display: '電話', property: 'tel', style: { width: '8%' } },
  { display: '郵區', property: 'zip' },
  { display: '住址', property: 'address', style: { width: '16%' } },
  { display: '緊急聯絡電話', property: 'erTel', style: { width: '8%' } },
];

function filterOutNonStudentField({
  remark,
  ...student
}: StudentFile): StudentFile {
  return { ...student, remark: '' };
}

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...state.auth,
});
const mapDispatch = ErrorDispatches;
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const importStudentPage: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案');
  const [fileData, setFileData] = useState([] as StudentFile[]);
  const [okToUpload, setOkToUpload] = useState(false);

  const { selectedSemester, yearSemElement: yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );

  return (
    <AuthedLayout>
      <Row className="mb-4 bg-warning py-2">此為匯入學生功能，若要更新學生資料，請至<a href="/import/update/student">批次更新學生資料</a></Row>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        <a href="/Doc/基本資料17.xls">學生資料匯入格式</a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const ss = (await inputFileParser.parseAsync(jsonData))?.map(
                    (s) => {
                      s.grade = selectedSemester.year - s.year + 1;
                      return s;
                    }
                  );
                  if (ss) {
                    // 檢查excel中年級班級座號、身分證有無重複
                    const duplicates: number[] =  [];
                    ss.forEach((student, index, self) => {
                      const isRepeat = self.some((s, i) =>
                        i !== index &&
                        ((s.grade === student.grade &&
                        s.no === student.no &&
                        s.seat === student.seat) ||
                        s.pid === student.pid)
                      );
                      if(isRepeat) {
                        duplicates.push(index + 2);
                      }
                    });
                    if(duplicates.length) {
                      showError(`excel檔中第${duplicates.join('、')}筆資料有重複的身分證或入學年、班級、座號，請檢查檔案資料內容是否正確`);
                    } else {
                      // 檢查YearStudentClass有無此學生
                      await Apis.checkClassesSeatStudentExists(
                        takeClassStudent(ss),
                        CheckField.Pid,
                        selectedSemester
                      )
                        .then((existsResult) => {
                          const students = existsResult.students;
                          const seatStudents = existsResult.seatStudents;
                          let studentfile = ss.map((s) => {
                            const result = students.find(
                              (r) => r.pid === s.pid
                            );
                            if (!result) return s;
                            if (result.exists) {
                              s.remark += (s.remark.length ? `，該學生已存在` : `該學生已存在`);
                            }
                            return s;
                          });
                          studentfile = ss.map((s) => {
                            const result = seatStudents.find(
                              (r) => r.pid === s.pid
                            );
                            if (!result) return s;
                            if (result.exists) {
                              s.remark += (s.remark.length ? `，該年級班級座號已有學生` : `該年級班級座號已有學生`);
                            }
                            return s;
                          });
                          setFileData(studentfile);
                          //check ss is okToUpload ,then setOkToUpload(true);
                          setOkToUpload(checkOkToUpload(true, ss));
                        })
                        .catch((e) => {
                          catchErrorForModal(e);
                          setOkToUpload(false);
                        });
                    }
                  } else {
                    // TODO: print error on UI
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              console.error(e);
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            }

            e.target.value = '';
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0)
              toast
                .promise(
                  Apis.insertClassesStudent(
                    fileData.map(filterOutNonStudentField),
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      <Row>
        <EditableTable
          headers={headerDisplayKeys}
          values={fileData}
          scrollable={true}
          onRowRender={({ remark }) => ({
            rowClassName: remark ? 'bg-danger text-white' : undefined,
          })}
        />
      </Row>
    </AuthedLayout>
  );
};

export const ImportStudentPage = connector(importStudentPage);
