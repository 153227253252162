import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import { z } from 'zod';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTable,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
} from '../components';
import Apis from '../apis';
import {
  PhiLab,
  PhiLabFile,
  PhiLabFileZod,
  ClassStudent,
  CheckField,
} from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import {
  CheckShouldUseIdentify,
  assignExists,
  takeClassStudent,
} from '../utils';
import { useActiveSems } from '../hook';
import { stat } from 'fs';

const inputFileParser = z.array(PhiLabFileZod);
const headerDisplayKeys: HeaderDisplayKeysWithType<PhiLabFile>[] = [
  { display: '備註', property: 'remark' },
  {
    display: '身份證字號',
    property: 'pid',
    style: { minWidth: '100px', width: '15%' },
  },
  {
    display: '學號',
    property: 'sid',
    style: { minWidth: '100px', width: '15%' },
  },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '尿液檢查', property: 'urine' },
  { display: '尿蛋白初檢', property: 'uProtein' },
  { display: '尿潛血初檢', property: 'uOB' },
  { display: '尿糖初檢', property: 'uGlucose' },
  { display: '酸鹼值初檢', property: 'uPh' },
  { display: '尿蛋白複檢', property: 'uProtein2' },
  { display: '尿潛血複檢', property: 'uOB2' },
  { display: '尿糖複檢', property: 'uGlucose2' },
  { display: '酸鹼值複檢', property: 'uPh2' },
  { display: '寄生蟲檢查', property: 'parasite' },
];

function filterOutNonPhiLabField({
  firstUrine,
  parasite,
  followUpUrine,
  parasiteExamDate,
  parasiteExamDate2,
  pid,
  sid,
  grade,
  no,
  seat,
}: PhiLabFile): PhiLab {
  return {
    pid,
    sid,
    grade,
    no,
    seat,
    urine: firstUrine,
    parasite: {
      inspect: 1,
      parasite,
      examDate: parasiteExamDate
    },
    followUpParasite: {
      inspect: 2,
      parasite,
      examDate: parasiteExamDate2
    },
    followUpUrine,
  };
}

function checkOkToUpload(okUpload: boolean, data: PhiLabFile[]): boolean {
  // console.log('checkOkToUpload: ',okUpload);
  if (okUpload) {
    const a = data.every(function (item) {
      return item.remark === '';
    });
    // console.log(a);
    return a;
  } else {
    return false;
  }
}

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = ErrorDispatches;
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const importPhiLabPage: FunctionComponent<Props> = ({
  user: { semesters, currentSemester },
  catchErrorForModal,
  showError,
}) => {
  const [fileName, setFileName] = useState('檔案');
  const [fileData, setFileData] = useState([] as PhiLabFile[]);
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { selectedSemester, yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );

  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        下載匯入格式：
        <a href="/Doc/實驗室檢查14.xls">實驗室檢查匯入格式</a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const ss = await inputFileParser.parseAsync(jsonData);
                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級座號"'
                      );
                      return;
                    }
                    await Apis.checkClassesStudentExists(
                      takeClassStudent(ss),
                      cf,
                      selectedSemester
                    )
                      .then((result) => {
                        // console.log('importPhiLab.ts #104',result)
                        // setFileData (merge result&ss)
                        setFileData(assignExists(matchFunc, ss, result));
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, ss));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            }
            e.target.value = '';
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (
              okToUpload &&
              fileData.length > 0 &&
              checkField != CheckField.None
            )
            console.log(fileData.map(filterOutNonPhiLabField))
              toast
                .promise(
                  Apis.insertPhiLab(
                    fileData.map(filterOutNonPhiLabField),
                    checkField,
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      {
        <Row>
          <EditableTable
            headers={headerDisplayKeys}
            values={fileData}
            scrollable={true}
            onRowRender={({ ...rest }) => ({
              rowClassName: rest.remark ? 'bg-danger text-white' : '',
            })}
          />
        </Row>
      }
    </AuthedLayout>
  );
};

export const ImportPhiLabPage = connector(importPhiLabPage);
